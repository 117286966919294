import { startOfToday, startOfDay, isAfter, isBefore } from 'date-fns';

import { Badge } from "@sravni/react-design-system"

export type PromoBadgeProps = {
    text: string,
    dateEnd: Date,
    dateStart?: Date
}

export const PromoBadge = (props: PromoBadgeProps) => {
    const { text, dateEnd, dateStart } = props

    const today = startOfToday();

    if (dateStart && isBefore(today, dateStart)) {
        return null
    }

    if(isAfter(today, startOfDay(dateEnd))) {
        return null
    }

    return <Badge color="violet" variant="primary" text={text} />
}